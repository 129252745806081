<template>
  <div :class="{ height_bar: !showBar }">
    <div :style="bgStyle1" class="bar_pos1">
      <div class="left_logo">
        <img
          :src="opacityStyle1.opacity === 1 ? logoBg : logo"
          alt=""
          class="bar_logo"
          @click="goIndex"
        />

        <div class="bar_list">
          <div v-for="(item, index) in $t('barList')" :key="index">
            <div
              v-if="index != 4"
              :class="{
                list_item_bor: curIndex === index,
                list_item_bgcolo: opacityStyle1.opacity === 1,
              }"
              class="list_item font16"
            >
              <a
                class="font16 font400w list_a"
                :href="`#${index + 1}`"
                @click="changePosition(index)"
              >
                {{ item.name }}
              </a>
            </div>
          </div>
        </div>
        <div class="right_changelanguages"></div>
        <!-- <div
          :class="opacityStyle1.opacity === 1 ? 'clb07' : 'clf100'"
          @click="changelanguages"
          class="right_changelanguages font16 font400w"
        >
          <span
            :class="
              $t('languages') == '中文'
                ? ''
                : opacityStyle1.opacity === 1
                ? 'clb03'
                : 'clf08'
            "
            >中</span
          >
          <span
            :class="
              $t('languages') == 'EN'
                ? ''
                : opacityStyle1.opacity === 1
                ? 'clb03'
                : 'clf08'
            "
            >/EN</span
          >
        </div> -->
        <!-- 未登录 -->
        <div
          v-if="!token"
          :class="opacityStyle1.opacity === 1 ? 'clb100' : 'clf100'"
          @click="goLogon"
          class="right_login font16 font500w"
        >
          {{ $t("loginData.login") }}
        </div>
        <!-- 已登录 -->
        <div v-if="token" class="right_login font16 clb100 font500w">
          <el-dropdown>
            <div class="login_top">
              <span
                :class="opacityStyle1.opacity === 1 ? 'clb100' : 'clf100'"
                class="el-dropdown-link"
              >
                {{ phone }}
              </span>
              <img
                :src="
                  opacityStyle1.opacity === 1
                    ? require('@/assets/img/index/Group 6823@2x.png')
                    : require('@/assets/img/index/Group 6823_2@2x.png')
                "
                alt=""
                class="login_icon"
              />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in $t('TopLoginData')"
                :key="index"
                @click.native="goNext(item.url)"
              >
                {{ item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  components: {},
  data() {
    return {
      showMenu: false,
      bgStyle1: {
        backgroundColor: "rgba(255,255,255," + 1 + ")",
      },
      opacityStyle1: {
        opacity: 1,
      },
      bgHeight: {
        height: "1040px",
      },
      logo: require("@/assets/img/index/LOGO 2@2x.png"),
      logoBg: require("@/assets/img/index/LOGO 2_1@2x.png"),
      curIndex: 0,
      token: localStorage.getItem("AZG_TOKEN"),
      phone: localStorage.getItem("AZG_USER"),
    };
  },
  props: {
    bgStyle: {
      type: Object,
      default() {
        return {
          backgroundColor: "rgba(255,255,255," + 1 + ")",
        };
      },
    },
    opacityStyle: {
      type: Object,
      default() {
        return {
          opacity: 1,
        };
      },
    },
    showBar: {
      type: Boolean,
      default: false,
    },
    curScroll: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleScroll() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let opacity = top / 100;
      opacity = opacity >= 1 ? 1 : opacity;
      this.bgStyle1 = { backgroundColor: "rgba(255,255,255," + opacity + ")" };
      this.opacityStyle1 = { opacity };
    },
    // 跳转登录
    goLogon() {
      this.$router.push("/login");
    },
    changelanguages() {
      this.$i18n.locale == "zh"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "zh");
      localStorage.setItem("AZG_LANGUAGE", this.$i18n.locale);
    },
    changePosition(index) {
      this.curIndex = index;
      this.$router.push("/#" + (index + 1));
      this.showMenu = false;
      if (index <= 7) {
        setTimeout(() => {
          let top =
            document.documentElement.scrollTop || document.body.scrollTop;
          window.scrollTo(0, top - 100);
        }, 0);
      }
      this.$emit("curIndex", this.curIndex);
    },
    goIndex() {
      this.curIndex = 0;
      this.$router.push("/");
      window.scrollTo(0, 0);
    },
    goNext(url) {
      if (!url) {
        localStorage.removeItem("AZG_TOKEN");
        localStorage.removeItem("AZG_USER");
        window.location.replace("/");
        this.$message({
          message: "退出登录成功",
          type: "none",
          customClass: "zidingyiel_message",
        });
        return;
      }
      this.$router.push(url);
    },
  },
  mounted() {
    this.curIndex = this.curScroll;
    if (this.showBar) {
      this.bgStyle1 = JSON.parse(JSON.stringify(this.bgStyle));
      this.opacityStyle1 = JSON.parse(JSON.stringify(this.bgStyle));
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped lang="less">
.height_bar {
  height: 75px;
}
.bar_pos1 {
  // background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 75px;
  display: flex;
  align-items: center;
  .bar_logo {
    display: flex;
    flex-shrink: 0;
    width: 71px;
    height: 70px;
    cursor: pointer;
  }
  .left_logo {
    display: flex;
    height: 100%;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
.bar_list {
  display: flex;
  height: 100%;
  justify-content: space-around;
  margin-left: calc(100vw * 80 / 1520);
  margin-right: calc(100vw * 80 / 1520);
  > div {
    display: flex;
  }
  // width: calc(44vw - 100vw * 80 / 1520 - 43px);
}
.right_changelanguages {
  cursor: pointer;
}
.right_login {
  cursor: pointer;
  margin-left: 30px;
}
.list_item_bor {
  border-top: 4px solid #bf9264;
}
.list_item {
  font-weight: 400;
  // margin: 46px 0;
  display: flex;
  align-items: center;
  // white-space: nowrap;
  margin-right: calc(100vw * 6 / 192);
  color: #fff;
  .list_a {
    color: #fff;
  }
  a:link {
    text-decoration: none;
    // color: #fff;
  }
  a:visited {
    text-decoration: none;
    // color: #fff;
  }
  a:hover {
    text-decoration: none;
    // color: #fff;
  }
  a:active {
    text-decoration: none;
    font-size: 14px;
    // color: #fff;
  }
}
.list_item_bgcolo > a:link {
  color: rgba(0, 0, 0, 0.87) !important;
}
.list_item_bgcolo > a:visited {
  color: rgba(0, 0, 0, 0.87) !important;
}
.list_item_bgcolo > a:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}
.list_item_bgcolo > a:active {
  color: rgba(0, 0, 0, 0.87) !important;
}
.bottom_img {
  margin-top: 190px;
  width: 100%;
  display: block;
}

// .content {
//   position: relative;
//   padding-top: 220px;
//   background: white;
// }
.login_icon {
  width: calc(100vw * 2.4 / 192);
  margin-left: calc(100vw * 1 / 192);
  min-width: 20px;
}
.login_top {
  display: flex;
  align-items: center;
}
</style>
