/*
 * @Descripttion:
 * @FilePath: /azg/src/utils/request.js
 * @Author: 张兴业
 * @Date: 2020-12-10 11:25:35
 * @LastEditors: 张兴业
 * @LastEditTime: 2020-12-13 17:04:42
 */

import { extend } from "umi-request";
import $message from "@/utils/element.js";

const APP_ID = "16adaee5-c23f-44f6-b6b3-c67e9aa7e45b";

/**
 * 异常处理程序
 */
const errorHandler = (error) => {
  const { response } = error;
  return response;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler,
  // 默认错误处理
  credentials: "include", // 默认请求是否带上cookie
  headers: {
    Accept: "application/json",
    "App-Id": APP_ID,
    languages: localStorage.getItem("AZG_LANGUAGE"),
  },
});

request.interceptors.request.use(async (url, options) => {
  let { headers } = options;
  if (!headers.Authorization) {
    let token = localStorage.getItem("AZG_TOKEN");
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  }

  return {
    url,
    options: {
      ...options,
      headers,
    },
  };
});

request.interceptors.response.use((response) =>
  response
    .json()
    .then((res) => {
      if (res.code === 401) {
        localStorage.removeItem("AZG_TOKEN");
        localStorage.removeItem("AZG_USER");
        $message.error(res.message);
        setTimeout(() => {
          //window.location.href = "/login";
        }, 1500);
      } else if (res.code === 0) {
        //
        $message({
          message: res.message,
          type: "none",
          // duration: 0,
          customClass: "zidingyiel_message",
        });
      }
      return res;
    })
    .catch(() => {
      $message({
        message: "获取数据失败，请稍后再试！",
        type: "none",
        customClass: "zidingyiel_message",
      });
    })
);

export default request;
