/*
 * @Descripttion:
 * @FilePath: /azg/src/main.js
 * @Author: 张兴业
 * @Date: 2020-10-23 10:15:36
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-07-08 15:18:18
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import request from "@/utils/request.js";
import i18n from "@/utils/i18n.js";
import "@/utils/isMinScreen.js";

import {
  Form,
  FormItem,
  Input,
  Autocomplete,
  Icon,
  Button,
  Link,
  Row,
  Col,
  Select,
  Option,
  OptionGroup,
  Image,
  MessageBox,
  Carousel,
  CarouselItem,
  Upload,
  Collapse,
  CollapseItem,
  Drawer,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Loading,
  Step,
  Steps,
  Cascader,
  DatePicker,
  Checkbox,
  Radio,
  RadioGroup,
  Dialog as ElDialog,
  Tabs as ElTabs,
  TabPane as ElTabPane,
  Divider,
  Tag,
  Tooltip
} from "element-ui";
import "@/styles/element/index.css";
import $message from "@/utils/element.js";

import {
  CountDown,
  Dialog,
  Form as VForm,
  Button as VButton,
  Icon as VIcon,
  Field as VField,
  ActionSheet,
  Cell,
  Tab,
  Tabs,
  Image as VImage
} from "vant";
import "vant/lib/index.css";

Vue.use(CountDown);
Vue.use(Dialog);
Vue.use(VForm);
Vue.use(VButton);
Vue.use(VIcon);
Vue.use(VField);
Vue.use(ActionSheet);
Vue.use(Cell);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(VImage);

Vue.config.productionTip = false;
Vue.use(Divider);
Vue.use(ElDialog);
Vue.use(Checkbox);
Vue.use(DatePicker);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Cascader);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Autocomplete);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Link);
Vue.use(Row);
Vue.use(Col);
Vue.use(Image);
Vue.use(Carousel);
Vue.use(CarouselItem);

Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Upload);
Vue.use(Tag);

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Drawer);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(ElTabs);
Vue.use(ElTabPane);
Vue.use(Loading);
Vue.use(Tooltip);

Vue.prototype.$request = request;

//将$message挂载到this上
Vue.prototype.$message = $message;
Vue.prototype.$confirm = MessageBox.confirm;

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.name === "ditui.register" && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
