import Vue from "vue";
let isMinScreen = {
  isPhone: document.documentElement.clientWidth <= 768
};
new Vue({
  data() {
    return {
      $$state: {
        isMinScreen
      }
    };
  }
});
window.addEventListener(
  "resize",
  function() {
    isMinScreen.isPhone = document.documentElement.clientWidth <= 768;
  },
  false
);

Vue.prototype.system = isMinScreen;
