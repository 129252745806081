<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/publicComponents/BottomDet/index.vue
 * @Author: 张兴业
 * @Date: 2020-10-25 13:31:19
 * @LastEditors: hl
 * @LastEditTime: 2024-09-26 14:16:55
-->
<template>
  <div class="footer_con">
    <div class="footer_container">
      <div class="footer_top">
        <img src="@/assets/index/logo.png" alt="logo" class="footer_img" />

        <!-- <div class="top_right_left">
            <div class="r_l_title">下载</div>
            <div class="det_font">
              <router-link to="/download/app/distributor">
                <span class="det_font">精品通</span>
              </router-link>
            </div>
          </div> -->

        <div class="footer_top_right" v-if="bottom.flag">
          <div class="top_right_left">
            <div class="r_l_title">{{ bottom.address.title }}</div>
            <div class="det_font">{{ bottom.address.add110 }}</div>
            <div class="det_font">{{ bottom.address.add1 }}</div>
            <div class="det_font mr_det">
              {{ bottom.address.add11 }}
            </div>
            <!-- <div class="det_font">{{ bottom.address.add2 }}</div>
            <div class="det_font mr_det">
              {{ bottom.address.add22 }}
            </div> -->
            <div class="det_font">{{ bottom.address.add3 }}</div>
            <div class="det_font mr_det">
              {{ bottom.address.add33 }}
            </div>
          </div>

          <div class="top_right_right">
            <div class="r_l_title">{{ bottom.relation.title }}</div>
            <a href="mailto:info@hooloogo.com">
              <div class="det_font">{{ bottom.relation.emal }}</div>
            </a>
          </div>
        </div>
        <div class="footer_top_right" v-else>
          <div class="top_right_left">
            <div class="r_l_title">{{ bottom.address.title }}</div>
            <!-- <div class="det_font">{{ bottom.address.add2 }}</div>
            <div class="det_font mr_det">
              {{ bottom.address.add22 }}
            </div> -->
            <div class="det_font">{{ bottom.address.add110 }}</div>
            <div class="det_font">{{ bottom.address.add1 }}</div>
            <div class="det_font mr_det">
              {{ bottom.address.add11 }}
            </div>
          </div>
          <div class="top_right_right">
            <div class="r_l_title">{{ bottom.relation.title }}</div>
            <a href="mailto:info@hooloogo.com">
              <div class="det_font">{{ bottom.relation.emal }}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom_det">
      <div class="det_font bott_3">{{ bottom.bottom.bot3 }}</div>
      <div class="det_font bott_1">
        <a href="http://beian.miit.gov.cn">{{ bottom.bottom.bot1 }}</a>
      </div>
      <div class="det_font bott_2">{{ bottom.bottom.bot2 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    bottom() {
      if (
        location.host === "www.suisenwangluo.xyz" ||
        location.host.indexOf("suisenwangluo.xyz") > -1
      ) {
        return {
          address: {
            title: this.$t("WapBottom.liaoAddress.title"),
            add110: this.$t("WapBottom.liaoAddress.add110"),
            add1: this.$t("WapBottom.liaoAddress.add1"),
            add11: this.$t("WapBottom.liaoAddress.add11"),
            add2: this.$t("WapBottom.liaoAddress.add2"),
            add22: this.$t("WapBottom.liaoAddress.add22"),
            add3: this.$t("WapBottom.liaoAddress.add3"),
            add33: this.$t("WapBottom.liaoAddress.add33"),
          },
          relation: {
            title: this.$t("WapBottom.liaoRelation.title"),
            emal: this.$t("WapBottom.liaoRelation.emal"),
          },
          bottom: {
            bot1: this.$t("WapBottom.liaoBottom.bot1"),
            bot2: this.$t("WapBottom.liaoBottom.bot2"),
          },
          flag: true,
        };
      }
      return {
        address: {
          title: this.$t("WapBottom.address.title"),
          add110: this.$t("WapBottom.address.add110"),
          add1: this.$t("WapBottom.address.add1"),
          add11: this.$t("WapBottom.address.add11"),
          add2: this.$t("WapBottom.address.add2"),
          add22: this.$t("WapBottom.address.add22"),
          add3: this.$t("WapBottom.address.add3"),
          add33: this.$t("WapBottom.address.add33"),
        },
        relation: {
          title: this.$t("WapBottom.relation.title"),
          emal: this.$t("WapBottom.relation.emal"),
        },
        bottom: {
          bot1: this.$t("WapBottom.bottom.bot1"),
          bot2: this.$t("WapBottom.bottom.bot2"),
          bot3: this.$t("WapBottom.bottom.bot3"),
        },
        flag: false,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.footer_con {
  // background: #383735;
  background: #fff;
  width: 100%;
  // height: calc(100vw * 461 / 1920);
  position: relative;
  margin-top: calc(100vw * 461 / 1920 * 5 / 461);

  .footer_container {
    border-top: 1px solid #e9e9e9;
    margin: 0 calc(100vw * 11 / 192);
    padding: calc(100vw * 2.9 / 192) calc(100vw * 21.4 / 192) 0;
    height: 100%;
    // margin-left: 19%;
    position: relative;

    .footer_top {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding-bottom: 24px;

      .footer_img {
        width: 200px;
        height: calc(200px * 133 / 200);
        margin-bottom: calc(100vw * 461 / 1920 * 60 / 461);
        margin: auto 0;
      }
      .footer_top_right {
        min-width: calc(100vw * 1191 / 1920 * 450 / 1191);
        margin: auto 0 auto auto;
        color: #fff;
        display: flex;
        justify-content: space-between;
        text-align: left;

        .top_right_left {
          // margin-left: ;
        }
      }
    }
  }
}
.det_font {
  font-size: 12px;
  font-weight: 200;
  color: #000;
  line-height: 17px;
  opacity: 0.5;
}
.bott_1 {
  // text-align: right;
  // margin-top: 24px;
  a {
    color: #000;
  }
}
.bott_2 {
  margin-left: 24px;
  // text-align: right;
  // margin-top: 10px;
  // padding-bottom: 24px;
  color: #000;
}
.bott_3 {
  margin-right: 24px;
  // text-align: right;
  // margin-top: 10px;
  // padding-bottom: 24px;
  color: #000;
}
.r_l_title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 20px;
  margin-bottom: 24px;
}
.mr_det {
  margin-bottom: 10px;
}
.bottom_det {
  border-top: 1px solid #e9e9e9;
  padding: 20px calc(100vw * 10.6 / 192);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
