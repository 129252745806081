/*
 * @Descripttion: 重写element事件
 * @FilePath: /azg/src/utils/element.js
 * @Author: 张兴业
 * @Date: 2020-12-13 17:01:29
 * @LastEditors: 张兴业
 * @LastEditTime: 2020-12-13 17:04:08
 */

import { Message } from "element-ui";

//定义一个新的Message方法，多传入一个offset参数
const $message = options => {
  return Message({
    ...options,
    offset: 120
  });
};

//重写一遍success的方法,将offset写入options
["success", "warning", "info", "error"].forEach(type => {
  $message[type] = options => {
    if (typeof options === "string") {
      options = {
        message: options,
        offset: 120
      };
    }
    options.type = type;
    return Message(options);
  };
});

export default $message;
