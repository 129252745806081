/*
 * @Descripttion:
 * @FilePath: /azg/src/views/download/store/axt/config.js
 * @Author: 张兴业
 * @Date: 2021-11-16 18:26:18
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-11-17 12:02:47
 */

const AxtConfig = {
  logo:
    "https://assets-img.hooloogostore.com/website/images/download/axt/logo_title.png",
  bg:
    "https://assets-img.hooloogostore.com/website/images/download/commons/bg.png",
  brand:
    "https://assets-img.hooloogostore.com/website/images/download/axt/brand.png",
  // app_title1: "az",
  // app_title2: "澳新通",
  hint_content: "*请微信扫码以下APP进行登入使用",
  ios_qrcode:
    "https://assets-img.hooloogostore.com/website/images/download/axt/app_qrcode_ios.png",
  android_qrcode:
    "https://assets-img.hooloogostore.com/website/images/download/axt/app_qrcode_android.png",
  ios_btn:
    "https://assets-img.hooloogostore.com/website/images/download/commons/ios_btn.png",
  android_btn:
    "https://assets-img.hooloogostore.com/website/images/download/commons/android_btn.png",
  ios_link: "itms-apps://itunes.apple.com/app/1590793270",
  android_link: "https://assets-apk.hooloogostore.com/app/axt-android.apk",
};

export default AxtConfig;
