import { render, staticRenderFns } from "./WapBottom.vue?vue&type=template&id=244f964a&scoped=true&"
import script from "./WapBottom.vue?vue&type=script&lang=js&"
export * from "./WapBottom.vue?vue&type=script&lang=js&"
import style0 from "./WapBottom.vue?vue&type=style&index=0&id=244f964a&lange=less&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "244f964a",
  null
  
)

export default component.exports