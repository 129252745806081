import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
let lang = (navigator.language || navigator.userLanguage).toLowerCase(); //常规浏览器语言和IE浏览器
let language = lang.substr(0, 2); //截取lang前2位字符
if (language != "zh") {
  language = "en";
}
console.log(lang, language == "zh" ? "中文" : "非中文");
let localLanguage = localStorage.getItem("AZG_LANGUAGE");
if (!localLanguage) {
  localStorage.setItem("AZG_LANGUAGE", language);
} else {
  language = localLanguage;
}

export default new VueI18n({
  locale: language, // 定义默认语言
  messages: {
    zh: require("@/assets/languages/zh.js").obj,
    en: require("@/assets/languages/en.js").obj
  }
});
