<!--
 * @Author: your name
 * @Date: 2021-02-01 17:08:26
 * @LastEditTime: 2024-09-26 14:17:18
 * @LastEditors: hl
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/views/publicComponents/WapBottom.vue
-->
<template>
  <div class="wap_bottom_con">
    <img src="@/assets/img/index/azg_1@2x.png" alt="logo" class="logo_img" />
    <el-collapse v-model="activeNames" @change="handleChange">
      <div v-if="bottom.flag">
        <el-collapse-item :title="bottom.address.title" name="1">
          <div class="item_con clb100 font300w wapfont14">
            {{ bottom.address.add110 }}
            {{ bottom.address.add1 }}
            {{ bottom.address.add11 }}
          </div>
          <!-- <div class="item_con clb100 font300w wapfont14">
            {{ bottom.address.add2 }}
            {{ bottom.address.add22 }}
          </div> -->
          <div class="item_con clb100 font300w wapfont14">
            {{ bottom.address.add3 }}
            {{ bottom.address.add33 }}
          </div>
        </el-collapse-item>
        <el-collapse-item :title="bottom.relation.title" name="2">
          <a href="mailto:info@hooloogo.com">
            <div class="item_con clb100 font300w wapfont14">
              {{ bottom.relation.emal }}
            </div>
          </a>
        </el-collapse-item>
      </div>
      <div v-else>
        <el-collapse-item :title="bottom.address.title" name="1">
          <!-- <div class="item_con clb100 font300w wapfont14">
            {{ bottom.address.add2 }}
            {{ bottom.address.add22 }}
          </div> -->
          <div class="item_con clb100 font300w wapfont14">
            {{ bottom.address.add110 }}
            {{ bottom.address.add1 }}
            {{ bottom.address.add11 }}
          </div>
        </el-collapse-item>
        <el-collapse-item :title="bottom.relation.title" name="2">
          <a href="mailto:info@hooloogo.com">
            <div class="item_con clb100 font300w wapfont14">
              {{ bottom.relation.emal }}
            </div>
          </a>
        </el-collapse-item>
      </div>
    </el-collapse>
    <div class="bottom_bot">
      <div class="bot wapfont14 font300w">
        {{ bottom.bottom.bot3 }}
      </div>
      <div class="bot wapfont14 font300w">
        <a class="clb" href="http://beian.miit.gov.cn">{{
          bottom.bottom.bot1
        }}</a>
        <!-- {{ $t("WapBottom.bottom.bot1") }} -->
      </div>
      <div class="bot wapfont14 font300w">
        {{ bottom.bottom.bot2 }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeNames: [1, 2],
    };
  },
  computed: {
    bottom() {
      if (
        location.host === "www.suisenwangluo.xyz" ||
        location.host.indexOf("suisenwangluo.xyz") > -1
      ) {
        return {
          address: {
            title: this.$t("WapBottom.liaoAddress.title"),
            add110: this.$t("WapBottom.liaoAddress.add110"),
            add1: this.$t("WapBottom.liaoAddress.add1"),
            add11: this.$t("WapBottom.liaoAddress.add11"),
            add2: this.$t("WapBottom.liaoAddress.add2"),
            add22: this.$t("WapBottom.liaoAddress.add22"),
            add3: this.$t("WapBottom.liaoAddress.add3"),
            add33: this.$t("WapBottom.liaoAddress.add33"),
          },
          relation: {
            title: this.$t("WapBottom.liaoRelation.title"),
            emal: this.$t("WapBottom.liaoRelation.emal"),
          },
          bottom: {
            bot1: this.$t("WapBottom.liaoBottom.bot1"),
            bot2: this.$t("WapBottom.liaoBottom.bot2"),
          },
          flag: true,
        };
      }
      return {
        address: {
          title: this.$t("WapBottom.address.title"),
          add110: this.$t("WapBottom.address.add110"),
          add1: this.$t("WapBottom.address.add1"),
          add11: this.$t("WapBottom.address.add11"),
          add2: this.$t("WapBottom.address.add2"),
          add22: this.$t("WapBottom.address.add22"),
          add3: this.$t("WapBottom.address.add3"),
          add33: this.$t("WapBottom.address.add33"),
        },
        relation: {
          title: this.$t("WapBottom.relation.title"),
          emal: this.$t("WapBottom.relation.emal"),
        },
        bottom: {
          bot1: this.$t("WapBottom.bottom.bot1"),
          bot2: this.$t("WapBottom.bottom.bot2"),
          bot3: this.$t("WapBottom.bottom.bot3"),
        },
        flag: false,
      };
    },
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>
<style lange="less" scoped>
.clb {
  color: #2c3e50;
}
.wap_bottom_con {
  margin-top: calc(100vw * 2.4 / 37.5);
  background: #f2f3f5;
  padding: calc(100vw * 3.2 / 37.5) calc(100vw * 2.4 / 37.5);
  text-align: left;

  opacity: 0.7;
}
.logo_img {
  text-align: left;
  display: block;
  width: calc(100vw * 3.1 / 37.5);
  height: auto;
  margin-bottom: calc(100vw * 1.2 / 37.5);
}
.wap_bottom_con >>> .el-collapse {
  border: none;
}
.wap_bottom_con >>> .el-collapse-item {
  margin-top: calc(100vw * 1.4 / 37.5);
}
.wap_bottom_con >>> .el-collapse-item__header {
  background-color: #f2f3f5 !important;
  border: none;
  font-size: calc(100vw * 1.4 / 37.5);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.wap_bottom_con >>> .el-collapse-item__wrap {
  background-color: #f2f3f5 !important;
  border: none;
}
.item_con {
  margin-top: calc(100vw * 1.2 / 37.5);
}
.bottom_bot {
  /* text-align: right; */
  margin-top: calc(100vw * 2.6 / 37.5);
  border-top: 1px solid rgba(121, 121, 122, 0.5);
}
.bottom_bot > .bot {
  margin-top: calc(100vw * 2.4 / 37.5);
  color: black;
  opacity: 0.5;
}
.bottom_bot > .bot > a {
  color: black;
}
.bot {
  margin-top: calc(100vw * 2.4 / 37.5);
}
</style>
